import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  standalone: true,
  imports: [NgIf],
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
  @Input() subTitle = '';
  @Input() title = '';
}

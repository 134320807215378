<div>
  <span
    (click)="onInsideClick($event)"
    #overlayTrigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="assign-btn"
    [ngClass]="[size, !!activity?.assignee ? 'assigned' : '']"
    [pTooltip]="tooltipContent"
    tooltipPosition="top"
  >
    @if (initials) {
      <span class="initials">{{ initials }}</span>
    } @else {
      <span class="plus">+</span>
    }
    <ng-template #tooltipContent>
      @if (initials) {
        <div class="text-white text-center font-bold text-xs">
          {{ activity.assignee?.first_name }} {{ activity.assignee?.last_name }}
        </div>
        <div class="text-white text-center text-size_s font-normal">
          <!--            @if (activity.assignee?.type_id === 2) { Manager } @else { Contractor }-->
        </div>
      } @else {
        <div class="text-white text-center font-bold text-xs">Assign to team</div>
      }
    </ng-template>
  </span>

  <app-overlay-general
    [show]="isOverlayShown"
    (showChange)="onOverlayShowChange($event)"
    [trigger]="overlayTrigger"
    [overlayPositions]="overlayPosition"
  >
    @if (showAssigneeOptions) {
      <app-default-options-list
        [listItems]="assignOptions"
        (selectedOption)="onAssignOptionSelected($event)"
      ></app-default-options-list>
    } @else {
      <app-assign-teammate-table
        *ngIf="isOverlayShown"
        [projectId]="activity?.project?.id"
        (teamMateSelected)="onTeamMateSelected($event, activity)"
      ></app-assign-teammate-table>
    }
  </app-overlay-general>
</div>
